import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ({ name, alt, style }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { in: ["jpg", "png"] } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 800, traceSVG: { color: "#003052" }) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const fluid = data.allFile.nodes.find(n => n.name === name).childImageSharp
    .fluid

  return fluid ? (
    <Img fluid={fluid} alt={alt} style={style} durationFadeIn={0} />
  ) : (
    <span>no data</span>
  )
}

export default Image
