import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useInView } from "react-intersection-observer"

const Nav = ({ siteTitle }) => {
  const [watchSticky, setWatchSticky] = useState(false)
  const [ref, inView] = useInView({ threshold: 1 })

  // NOTE: On the initial render, inView is false until the intersection
  // observer figures things out. This results in the sticky class being
  // applied for a split second on page load which results in some flickering
  // styles. This useEffect call delays the setting of a bit of state that
  // is checked before applying the sticky class.
  useEffect(() => {
    setTimeout(() => {
      setWatchSticky(true)
    }, 1000)
  }, [watchSticky])

  return (
    <div
      id="stickyNavbar"
      ref={ref}
      className={watchSticky ? (inView ? "" : "sticky") : ""}
    >
      <div className="container container-full">
        {/* <span className="download-link">
          <a
            target="_blank"
            href="/BringingHealthcareHome-IndustryStatusReport.pdf"
          >
            Download the PDF
          </a>
        </span> */}
        <span className="title">Everything Old is New Again</span>
        <nav className="dropdown">
          <span className="trigger">
            <span>▼</span> Contents
          </span>
          <ul className="wash-blue-steel">
            <li><a href="#summary">Executive Summary</a></li>
            <li><a href="#welcome">Welcome</a></li>
            <li><a href="#home-as-a-site-of-care">Home as a Site of Care</a></li>
            <li><a href="#social-determinants-of-health">Social Determinants of Health and Stigma</a></li>
            <li><a href="#delivery-overcoming-obstacles">Delivery: Overcoming Obstacles</a></li>
            <li><a href="#major-players">Major Players in Prescription Access</a></li>
            <li><a href="#conclusion">Conclusion: The Future of Care</a></li>
            <li><a href="#advisory-council">Advisory Council</a></li>
            <li><a href="#glossary">Glossary</a></li>
            <li><a href="#sources">Sources</a></li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

Nav.propTypes = {
  siteTitle: PropTypes.string,
}

Nav.defaultProps = {
  siteTitle: ``,
}

export default Nav
