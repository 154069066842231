/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { ParallaxProvider } from "react-scroll-parallax"
import Nav from "./nav"
import Hero from "./hero"
import "reset-css"
import "./base.scss"

import "../../node_modules/@fortawesome/fontawesome-pro/css/all.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ParallaxProvider>
        <Hero />
        <Nav siteTitle={data.site.siteMetadata.title} />

        <main>{children}</main>
      </ParallaxProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
