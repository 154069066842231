import React from "react"
import { Parallax } from "react-scroll-parallax"

import HeroBackground from "../images/header/header-background-pattern.png"
import Image from "../components/image"

const Hero = () => {
  return (
    <header className="wash-white no-wedge" id="hero">

      <div id="heroArt">
        <div
          id="heroStockBackground"
          style={{
            zIndex: 4,
            position: "absolute",
            top: 0,
            left: "5%",
            top: "5%",
            width: "90%",
            height: "90%",
            backgroundImage: `url(${HeroBackground})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat"
          }}
        >
                  <Parallax
          y={["150px", "0px"]}
          
        ><Image
            name="header-foreground-hands"
            alt=""
            style={{
              zIndex: 5,
              position: "absolute",
              top: "8%",
              left: "-5%",
              width: "110%",
            }}
          /></Parallax>
        
          
        </div>
      </div>
        
      <div id="heroText">
        <h1 className="is-navy">Everything Old is New&nbsp;Again</h1>
        <h3 className="is-royal">
          Social Determinants of Health and the Role of Prescription Delivery in Home&nbsp;Healthcare
        </h3>

        <p className="is-goldenrod">Industry Status Report, July 2022</p>
      </div>
    </header>
  )
}

export default Hero
